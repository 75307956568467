import { LoginFormWrapper } from "./Popup.styles";
import { lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
const MenuDashboard = lazy(() => import("@/components/secure/MenuDashboard"));
//import MenuDashboard from '@/components/secure/MenuDashboard';

const LoggedPopup = ({
  menus,
  isLoginPopupVisible,
  setIsLoginPopupVisible,
}) => {
  const navigate = useNavigate();
  const handleMenuClick = (menu) => {
    navigate(menu, { replace: true });
    setIsLoginPopupVisible(false);
  };

  return (
    <Suspense>
      <LoginFormWrapper className="user-login">
        <MenuDashboard
          menus={menus}
          handleMenuClick={handleMenuClick}
          isLoginPopupVisible={isLoginPopupVisible}
          setIsLoginPopupVisible={setIsLoginPopupVisible}
          $isPopup
        />
      </LoginFormWrapper>
    </Suspense>
  );
};
export default LoggedPopup;
