import { Link, NavLink } from "react-router-dom";
import SetMenu from "@/utils/SetMenu";
import { useContext, useEffect, useRef, useState, lazy, Suspense } from "react";

import { appDataContext } from "@/context";
const Icon = lazy(() => import("@/utils/Icon"));
//import Icon from "@/utils/Icon";
const LanguageSelector = lazy(() => import("@/components/LanguageSelector"));
//import LanguageSelector from "@/components/LanguageSelector";
//const LoggedPopup = lazy(() => import("@/components/popups/LoggedPopup"));
import LoggedPopup from "@/components/popups/LoggedPopup";
//const LoginPopup = lazy(() => import("@/components/popups/LoginPopup"));
import LoginPopup from "@/components/popups/LoginPopup";
import { isLoggedIn } from "@/api/createTokenProvider";
import styled from "styled-components";
import theme from "@/utils/Theme";
import { useCartStore } from "@/store/cart-store";
import { useLangStore } from "@/store/lang-store";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "@/store/user-store";

const Menu = () => {
  const isLogged = isLoggedIn();
  const userStore = useUserStore.getState().userStore;
  const dataContext = useContext(appDataContext);
  const lang = dataContext?.lang;
  const routeMap = dataContext?.routeMap;
  const navigate = useNavigate();
  const { cartStore } = useCartStore();
  const { langStore } = useLangStore();
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);
  const [animatePopup, setAnimatePopup] = useState(false);
  const [, setIsAccountCreationPopupVisible] = useState(false);
  const [menus, setMenus] = useState(null);

  const MainMenu = () => {
    return lang?.navigation.menu?.map((item, i) => (
      <li key={`MainMenu_${i}`}>
        <NavLink
          to={`${routeMap?.urls[item?.url] ?? item?.url}`}
          className={({ isActive }) => (isActive ? "active" : "")}
          key={`MainMenu_${i}`} // {i}
        >
          {item?.label}
        </NavLink>
      </li>
    ));
  };
  const catMenu = useRef(null);
  const btMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (!catMenu.current?.contains(e.target) && e.target != btMenu.current) {
      setIsLoginPopupVisible(false);
    } else {
      e.stopPropagation();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  const openAccountCreationPopup = () => {
    setIsAccountCreationPopupVisible(true);
  };
  useEffect(() => {
    window.addEventListener("openAccountPopup", openAccountCreationPopup);
    return () =>
      window.removeEventListener("openAccountPopup", openAccountCreationPopup);
  }, []);

  useEffect(() => {
    setMenus(SetMenu({ dataContext }));
  }, [langStore, dataContext]);

  function closePopupWithDelay(timer) {
    if (isLoginPopupVisible) {
      setAnimatePopup(true);
      setTimeout(() => {
        //setIsLoginPopupVisible(false);
        setAnimatePopup(false);
      }, timer);
    }
  }

  return (
    <MainNavWrapper>
      <Link to={routeMap?.urls.home ?? "/"}>
        <Logo
          src="/images/logo.svg"
          alt="logo"
          width={314}
          height={70}
          loading="lazy"
        />
      </Link>
      <Right>
        <MenuHead>
          <Suspense>
            <LanguageSelector />
          </Suspense>
          <Link to={routeMap?.urls.contact} className="help">
            <Suspense>
              <Icon
                icon="info"
                size={14}
                width={14}
                height={14}
                color={theme.colors.black}
              />
            </Suspense>
            {lang?.help.label}
          </Link>
        </MenuHead>
        <Nav>
          <MenuItems>
            <MainMenu />
          </MenuItems>
          <CTAWrapper>
            <MenuButton
              ref={btMenu}
              onClick={(e) => {
                e.stopPropagation();
                setIsLoginPopupVisible(!isLoginPopupVisible);
              }}
              className={isLogged ? "active" : ""}
            >
              {!isLogged
                ? lang?.navigation.account.label
                : (userStore?.firstName
                    ? userStore?.firstName?.substring(0, 1)
                    : lang?.navigation.account.label) +
                  (userStore?.lastName && userStore?.firstName
                    ? userStore?.lastName?.substring(0, 1)
                    : "")}
            </MenuButton>
            {isLoginPopupVisible && (
              <PopupWrapper ref={catMenu}>
                {isLogged && !animatePopup ? (
                  <LoggedPopup
                    menus={menus}
                    isLoginPopupVisible={isLoginPopupVisible}
                    setIsLoginPopupVisible={setIsLoginPopupVisible}
                  />
                ) : (
                  <LoginPopup closePopupWithDelay={closePopupWithDelay} />
                )}
              </PopupWrapper>
            )}
            {cartStore && (
              <CaddyButton
                onClick={() =>
                  navigate(`${routeMap?.urls.checkout}/${cartStore?.key}`)
                }
              >
                <Suspense>
                  <Icon icon="cart-simple" size={14} />
                </Suspense>
                <Green className="badge badge-cart">1</Green>
              </CaddyButton>
            )}
          </CTAWrapper>
        </Nav>
      </Right>
    </MainNavWrapper>
  );
};

export default Menu;

const Green = styled.span`
  border-radius: 50%;

  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.green};
  color: transparent;
  font-size: 0;
  position: absolute;
  right: 13px;
  top: 13px;
  width: 6px;
  height: 6px;
`;

const MenuHead = styled.div.attrs({
  className: "MenuHead",
})`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  justify-content: flex-end;

  select,
  label {
    border: none;
  }

  .help {
    right: 0;
    margin-left: 16px;
    color: inherit;
  }
`;

const Right = styled.div.attrs({
  className: "Right",
})`
  width: 860px;
  height: 81px;
`;

const MenuItems = styled.ul.attrs({
  className: "MenuItems",
})`
  * {
    color: ${(props) => props.theme.colors.dark};
  }
  display: flex;
  list-style: none;
  gap: 16px;
  margin: 0 24px 0 0;
  font-size: 1.6rem;

  li a {
    font-family: ${(props) => props.theme.fonts.title};
    text-decoration: none;
  }
`;
const CTAWrapper = styled.div.attrs({
  className: "CTAWrapper",
})`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 0;
  flex-wrap: nowrap;
`;
const Logo = styled.img.attrs({
  className: "Logo",
})`
  max-width: 313px;
  max-height: 70px;
  width: 100%;
  height: fit-content;
  min-height: 70px;
  @media only screen and (min-width: 1200px) {
    width: 313px;
    height: 70px;
  }
`;

const CaddyButton = styled.button.attrs({
  className: "CaddyButton",
})`
  display: inline-flex;
  font-family: ${(props) => props.theme.fonts.title};
  margin-bottom: 0;
  text-transform: none;
  white-space: nowrap;
  background-color: white;
  color: ${(props) => props.theme.colors.blue};
  padding: 11px 16px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-left: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
  svg {
    position: relative;
    top: 3px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    fill: ${(props) => props.theme.colors.blue};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
    svg {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

const MenuButton = styled.button.attrs({
  className: "MenuButton",
})`
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.title};
  margin-bottom: 0;
  text-transform: none;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.blue};
  padding: 11px 16px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all 0.2s ease;
  &:hover,
  &.active {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
  }
`;

const MainNavWrapper = styled.div.attrs({
  className: "MainNavWrapper",
})`
  z-index: 100;
  max-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0;
`;

const Nav = styled.nav.attrs({
  className: `main-navigation`,
})`
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 18px;

  a {
    position: relative;
    white-space: nowrap;
    &::after {
      display: block;
      background-color: ${(props) => props.theme.colors.blue};
      top: 120%;
      content: "";
      height: 2px;
      left: 50%;
      position: absolute;
      width: 0%;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }
    &.active {
      color: ${(props) => props.theme.colors.blue};
    }
    &.active,
    &:hover {
      color: ${(props) => props.theme.colors.blue};
      &::after {
        width: 100%;
        left: 0;
        opacity: 1;
      }
    }
  }
`;

const PopupWrapper = styled.div.attrs({
  className: "PopupWrapper",
})`
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateX(-300px);
  z-index: 10;
  width: 300px;
`;
