import { Suspense, lazy, useEffect, useState } from "react";
import { changeAppLanguage, determineLang } from "@/utils/languageUtils";

import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import ThemeProviderContainer from "@/utils/ThemeProviderContainer";
import { appDataContext } from "@/context";
//import GlobalStyle from "@/utils/GlobalStyle";
// const { themeProviderContainer } = lazy(() =>
//   import("@/utils/ThemeProviderContainer")
// );
import { getUserToken } from "@/api/createTokenProvider";
import { useLangStore } from "@/store/lang-store";
import ReactGA from "react-ga4";
//const Routes = lazy(() => import("@/layouts/Routes"));
import Routes from "@/layouts/Routes";
const GlobalStyle = lazy(() => import("@/utils/GlobalStyle"));
const ScrollToTop = lazy(() => import("@/utils/ScrollToTop"));
//import ScrollToTop from "@/utils/ScrollToTop";
const RccCookie = lazy(() => import("@/components/cookies/RccCookie"));
// import CookiesComponent from "@/components/cookies/CookiesComponent";
const ModalLoginCentered = lazy(() =>
  import("@/components/Modals/ModalLoginCentered")
);
//import ModalLoginCentered from "@/components/Modals/ModalLoginCentered";
const ResetPasswordPopup = lazy(() =>
  import("@/components/popups/ResetPasswordPopup")
);
//import ModalResetCentered from "@/components/Modals/ModalResetCentered";
const AccountCreationPopup = lazy(() =>
  import("@/components/popups/AccountCreationPopup")
);
// import AccountCreationPopup from "@/components/popups/AccountCreationPopup";

const InviteNewsletterCookie = lazy(() =>
  import("./components/cookies/InviteNewsletterCookie")
);
// import Cookie5Days from "./utils/cookies";

const MazoutLoader = lazy(() => import("@/components/_common/MazoutLoader"));

// Create a new context object using the createContext function
// This context will be used to share data between components
//export const appDataContext = createContext();

// The App component is the root component of our application
const App = () => {
  const [lang, setLang] = useState(null);
  const [properties, setProperties] = useState(null);
  const [routeMap, setRouteMap] = useState(null);
  const { langStore, setLangStore } = useLangStore();
  const [, setUserValue] = useState(getUserToken());
  const [isAccountPopupVisible, setIsAccountPopupVisible] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(false);
  const [displayReset, setDisplayReset] = useState(false);
  const [displayResetConditions, setDisplayResetConditions] = useState(null);
  const [urlToRedirect, setUrlToRedirect] = useState(null);
  const initialLang = determineLang();

  ReactGA.initialize("G-713T51SF38");

  useEffect(() => {
    // console.log(
    //   "🚀 ~ 1. useEffect setlangStore with initialLang:",
    //   initialLang
    // );
    // const urlArray = window.location.pathname.split('/');
    if (initialLang) {
      // console.log(
      //   "🚀 ~ 1.1 useEffect setlangStore with initialLang:",
      //   initialLang
      // );
      changeAppLanguage(initialLang, setLang, setProperties, setRouteMap);
      setLangStore(initialLang);
    }

    //changeAppLanguage(initialLang, setLang, setProperties, setRouteMap);
  }, [initialLang, setLangStore]);

  useEffect(() => {
    // console.log(
    //   "🚀 ~ 2. useEffect ~ changeAppLanguage with langStore:",
    //   langStore
    // );
    if (langStore) {
      changeAppLanguage(langStore, setLang, setProperties, setRouteMap);
    }
  }, [langStore]); //no more useless parameters.

  // const changeAppLanguageCall = useCallback(() => {
  //   changeAppLanguage(langStore, setLang, setProperties, setRouteMap);
  // }, [langStore]); //no more useless parameters.

  // // Call the getCurrentLanguage function when the component mounts
  // useEffect(() => {

  //   // setLangStore(localStorage.getItem('lang') || 'nl-BE');
  //   // Respond to the `storage` event
  //   changeAppLanguageCall(); // now it's changeAppLanguageCall
  // }, [langStore, changeAppLanguageCall]);

  /// Log the data and lang variables to the console for debugging purposes
  const openLoginPopup = (e) => {
    //console.log(e);
    e?.detail?.redirect ? setUrlToRedirect(e.detail.redirect) : null;
    setDisplayLogin(true);
  };

  const closeAllPopup = () => {
    setDisplayReset(false);
    setDisplayLogin(false);
    setIsAccountPopupVisible(false);
    document.body.classList.remove("modal-open");
  };

  const openeResetPopup = (e) => {
    e?.detail ? setDisplayResetConditions(e.detail) : null;
    setDisplayReset(true);
  };

  const handleStorage = () => {
    const userToken = getUserToken();
    setUserValue(userToken);
  };
  const toggleAccountPopup = () => setIsAccountPopupVisible((prev) => !prev);

  useEffect(() => {
    window.addEventListener("tokenUserChanged", handleStorage);
    window.addEventListener("ToogleAccountPopup", toggleAccountPopup);
    window.addEventListener("OpenLoginForm", openLoginPopup);
    window.addEventListener("CloseAnyForm", closeAllPopup);
    window.addEventListener("OpenResetPasswordForm", openeResetPopup);
    return () => {
      window.removeEventListener("OpenResetPasswordForm", openeResetPopup);
      window.removeEventListener("CloseAnyForm", closeAllPopup);
      window.removeEventListener("OpenLoginForm", openLoginPopup);
      window.removeEventListener("ToogleAccountPopup", toggleAccountPopup);
      window.removeEventListener("tokenUserChanged", handleStorage);
    };
  }, []);

  return (
    <ThemeProviderContainer>
      {console.log("App")}
      <Suspense fallback={<MazoutLoader $logo />}>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop />
          <appDataContext.Provider value={{ routeMap, lang, properties }}>
            <CookiesProvider>
              <HelmetProvider>
                {isAccountPopupVisible && (
                  <Suspense>
                    <AccountCreationPopup
                      setIsAccountPopupVisible={setIsAccountPopupVisible}
                    />
                  </Suspense>
                )}
                {displayLogin && (
                  <Suspense>
                    <ModalLoginCentered
                      isModalLogin={true}
                      setDisplayLogin={setDisplayLogin}
                      displayLogin={displayLogin}
                      redirectAterLogin={urlToRedirect}
                      setRedirectAterLogin={setUrlToRedirect}
                    />
                  </Suspense>
                )}
                {displayReset && (
                  <Suspense>
                    <ResetPasswordPopup
                      setDisplayReset={setDisplayReset}
                      {...displayResetConditions}
                    />
                  </Suspense>
                )}

                {lang && <Routes />}

                <Suspense>
                  <RccCookie />
                </Suspense>
                <Suspense>
                  <InviteNewsletterCookie />
                </Suspense>
              </HelmetProvider>
            </CookiesProvider>
          </appDataContext.Provider>
        </BrowserRouter>
      </Suspense>
    </ThemeProviderContainer>
  );
};

export default App;
