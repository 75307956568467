import { createContext } from "react";

// No need to export this, we'll create custom hook around it
export const appDataContext = createContext();

// // Custom hook, returns array with state, setter
// export const useappDataContext = () => useContext(appDataContext);

// // Convenience hook so you don't have to destructure
// // everywhere, returns read-only state
// export const useappDataContextState = () => {
//   const [state] = useContext(appDataContext);
//   return state;
// };

// // Provider, goes in render tree above components where you
// // // import/use the accessor hooks above.
// export const appDataContextProvider = ({ children, init }) => {
//   const myCtx = useState(init); // [myCtxState, setMyCtxState]
//   return <appDataContext.Provider value={myCtx}>{children}</appDataContext.Provider>;
// };
