import { Navigate, Outlet, useParams } from 'react-router-dom';

export const CountryRoutes = () => {
  const { country } = useParams();
  const allowedCountries = ["belgique", "belgie"];

  return (allowedCountries.includes(country)) ? (
    <Outlet />
  ) : (
    <Navigate to='/' />
  );
};
