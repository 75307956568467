import { LOGIN_URL, PSWD_URL, apiPostCall } from "@/api/apiDispatcher";
import { Suspense, lazy, useContext, useEffect, useRef, useState } from "react";
import { getUserToken, setUserToken } from "@/api/createTokenProvider";
import { useNavigate } from "react-router-dom";
import { appDataContext } from "@/context";
import { useLangStore } from "@/store/lang-store";
const LoginForm = lazy(() => import("@/components/forms/LoginForm"));
//import LoginForm from '@/components/forms/LoginForm';

const LoginPopup = ({
  closePopupWithDelay,
  $isPopup,
  $noRegiter,
  ...props
}) => {
  const userRef = useRef();
  const { langStore } = useLangStore();
  const [pwdForgot, setPwdForgot] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const dataContext = useContext(appDataContext);
  const text = dataContext?.lang;
  const [userTokenValue] = useState(getUserToken());
  const [justLogged, setJustLogged] = useState(userTokenValue ? true : false);
  const navigateTo = useNavigate();

  useEffect(() => {
    userRef?.current?.focus();
    setErrorMsg("");
    return () => {};
  }, []);

  useEffect(() => {
    setErrorMsg("");
    setSuccessMsg("");
  }, [pwdForgot]);

  const handleLoginSubmit = async (data) => {
    setErrorMsg("");
    const url = !pwdForgot ? LOGIN_URL : PSWD_URL;

    apiPostCall(url, langStore, data)
      .then((response) => {
        //console.log(response?.data?.result?.data);
        setSuccessMsg(response?.data?.result?.data);

        if (!pwdForgot) {
          let userToken = response?.data?.data;
          setUserToken(userToken);
          setJustLogged(true);
          if (props && props?.redirectAterLogin) {
            closePopupWithDelay(0);
            navigateTo(props?.redirectAterLogin, {
              replace: true,
              state: {},
            });
            if (props && props?.setRedirectAterLogin) {
              props.setRedirectAterLogin(null);
            }
          } else {
            closePopupWithDelay(2000);
          }
        }
      })
      .catch((err) => {
        if (!err?.response) {
          setErrorMsg(text?.form.NoServerResponse);
        } else {
          //console.log(err?.response);
          setErrorMsg(err?.response?.data?.title);
        }
      });
  };

  const openAccountPopup = () => {
    closePopupWithDelay(0);
    window.dispatchEvent(
      new CustomEvent("ToogleAccountPopup", {
        open: true,
      })
    );
  };

  return (
    <Suspense>
      <LoginForm
        pwdForgot={pwdForgot}
        setPwdForgot={setPwdForgot}
        successMsg={successMsg && (justLogged || pwdForgot) ? successMsg : ""}
        errorMsg={errorMsg}
        handleLoginSubmit={handleLoginSubmit}
        openAccountPopup={openAccountPopup}
        text={text}
        $isPopup={$isPopup}
        $noRegiter={$noRegiter}
      />
    </Suspense>
  );
};
export default LoginPopup;
