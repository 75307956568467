import { LOGIN_URL, PSWD_URL, apiPostCall } from "@/api/apiDispatcher";
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import SetMenu from "@/utils/SetMenu";
import {
  getUserToken,
  isLoggedIn,
  setUserToken,
} from "@/api/createTokenProvider";

import { appDataContext } from "@/context";
import styled from "styled-components";
import theme from "@/utils/Theme";
import { useLangStore } from "@/store/lang-store";
import { useScreenDetector } from "@/utils/useScreenDetector";

const LanguageSelector = lazy(() => import("@/components/LanguageSelector"));
//import LanguageSelector from "@/components/LanguageSelector";

const Icon = lazy(() => import("@/utils/Icon"));
//import Icon from "@/utils/Icon";
const LoginForm = lazy(() => import("./forms/LoginForm"));
//import LoginForm from "./forms/LoginForm";
const MainSearch = lazy(() => import("./MainSearch"));
//import MainSearch from "./MainSearch";
const MenuDashboard = lazy(() => import("@/components/secure/MenuDashboard"));
//import MenuDashboard from "@/components/secure/MenuDashboard";
const MazoutLoader = lazy(() => import("@/components/_common/MazoutLoader"));

const MenuMobile = () => {
  const { langStore } = useLangStore();
  const [pwdForgot, setPwdForgot] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const dataContext = useContext(appDataContext);
  const text = dataContext?.lang;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMenuDashboardOpen, setIsMenuDashboardOpen] = React.useState(false);
  const [, setIsMobileViewCroped] = useState(false);
  const { isMobile, isTablet } = useScreenDetector();
  const [userTokenValue] = useState(getUserToken());
  const [isMenuCropped, setIsMenuCropped] = useState(false);
  const [justLogged, setJustLogged] = useState(userTokenValue ? true : false);
  const [menus, setMenus] = useState(null);
  const menuMobileNav = document.querySelector(
    '[data-testid="AccountWrapper"]'
  );

  useEffect(() => {
    if (isMobile && window.innerHeight < window.visualViewport.height) {
      setIsMobileViewCroped(true);
    } else {
      setIsMobileViewCroped(false);
    }
  }, [isMobile, isMenuOpen]);

  useEffect(() => {
    setMenus(SetMenu({ dataContext }));
  }, [langStore, dataContext]);

  const navigate = useNavigate();
  const lang = dataContext?.lang;
  const routeMap = dataContext?.routeMap;

  const handleMenuClick = (menu) => {
    navigate(menu, { replace: true });
    toggleMenuDashboard(true);
  };

  const openAccountPopup = () => {
    toggleMenuDashboard(true);
    window.dispatchEvent(
      new CustomEvent("ToogleAccountPopup", {
        open: true,
      })
    );
  };

  const handleCroppedMenu = useCallback(() => {
    if (menuMobileNav?.offsetHeight < window?.visualViewport?.height) {
      setIsMenuCropped(true);
    } else {
      setIsMenuCropped(false);
    }
  }, [menuMobileNav]);
  const toggleMenu = (forceClose) => {
    if (isMenuOpen || forceClose === true) {
      setIsMenuOpen(false);
    } else {
      setIsMenuDashboardOpen(false);
      setIsMenuOpen(true);
    }
    document.body.classList.remove("modal-open");
  };

  const toggleMenuDashboard = (forceClose) => {
    if (isMenuDashboardOpen || forceClose === true) {
      setIsMenuDashboardOpen(false);
    } else {
      setIsMenuOpen(false);
      setIsMenuDashboardOpen(true);
    }
  };

  const ToogleMenuFromRemote = useCallback(() => {
    setIsMenuDashboardOpen(false);
    setIsMenuOpen(true);
  }, []);

  useEffect(() => {
    window.addEventListener("ToogleAccountMenu", ToogleMenuFromRemote);
    return () =>
      window.removeEventListener("ToogleAccountMenu", ToogleMenuFromRemote);
  }, [ToogleMenuFromRemote]);

  useEffect(() => {
    if (isMenuOpen) {
      // if menuMobileNav.clientHeight is < wiewport height then set isMenuCropped to true
      handleCroppedMenu();
    }
  }, [isMenuOpen, handleCroppedMenu]);

  useEffect(() => {
    const handleResize = () => {
      handleCroppedMenu();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      //setIsMenuOpen(false);
      document.body.classList.remove("modal-open");
    };
  }, [handleCroppedMenu]);

  useEffect(() => {
    setErrorMsg("");
  }, []);

  useEffect(() => {
    setErrorMsg("");
    setSuccessMsg("");
  }, [pwdForgot]);

  useEffect(() => {
    if (!isMenuDashboardOpen) {
      setErrorMsg("");
      setSuccessMsg("");
    }
  }, [isMenuDashboardOpen]);

  const handleLoginSubmit = async (data) => {
    setErrorMsg("");
    const url = !pwdForgot ? LOGIN_URL : PSWD_URL;

    apiPostCall(url, langStore, data, false)
      .then((response) => {
        setSuccessMsg(response?.data?.result?.data);

        if (!pwdForgot) {
          let userToken = response?.data?.data;
          setUserToken(userToken);
          setJustLogged(true);
          //closePopupWithDelay(2000);
        }
      })
      .catch((err) => {
        if (!err?.response) {
          setErrorMsg(text?.form.NoServerResponse);
        } else {
          //console.log(err?.response);
          setErrorMsg(err?.response?.data?.title);
        }
      });
  };

  return (
    <Wrapper>
      <TopBarMenuWrapper>
        <Link to="/">
          <Logo
            src="/images/logo.svg"
            alt="logo"
            width={300}
            height={66}
            loading="lazy"
          />
        </Link>
        <RightMenuPart>
          {isTablet && (
            <DashboardMenuToggle role="button" onClick={toggleMenuDashboard}>
              <Suspense>
                <Icon
                  icon="user-circle"
                  size={30}
                  width={30}
                  height={30}
                  color={theme.colors.blue}
                />
              </Suspense>
            </DashboardMenuToggle>
          )}
          <Burger className="menu-burger" role="button" aria-label="Menu">
            <input
              type="checkbox"
              id="checkbox3"
              aria-label="Menu"
              className="checkbox3 visuallyHidden"
              checked={isMenuOpen}
              onChange={() => {}}
            />
            <div onClick={toggleMenu}>
              <div className="hamburger hamburger3">
                <span className="bar bar1"></span>
                <span className="bar bar2"></span>
                <span className="bar bar3"></span>
                <span className="bar bar4"></span>
              </div>
            </div>
          </Burger>
        </RightMenuPart>
      </TopBarMenuWrapper>
      <MenuMobileNav
        className={isMenuDashboardOpen ? "active" : ""}
        data-testid="MenuMobileNav"
      >
        {isMenuDashboardOpen && (
          <Suspense fallback={<MazoutLoader />}>
            <CloseButtonWrapper>
              <Suspense>
                <LanguageSelector />
              </Suspense>
              <button
                type="button"
                onClick={() => toggleMenuDashboard(true)}
                aria-label="Close modal"
              >
                <Suspense>
                  <Icon icon="xmark-circle-positif" size={30} />
                </Suspense>
              </button>
            </CloseButtonWrapper>
            {isLoggedIn() ? (
              <Suspense>
                <MenuDashboard
                  menus={menus}
                  handleMenuClick={handleMenuClick}
                  isLoginPopupVisible={isMenuDashboardOpen}
                  setIsLoginPopupVisible={setIsMenuDashboardOpen}
                  $isPopup
                />
              </Suspense>
            ) : (
              <Suspense>
                <LoginForm
                  pwdForgot={pwdForgot}
                  setPwdForgot={setPwdForgot}
                  successMsg={
                    successMsg && (justLogged || pwdForgot) ? successMsg : ""
                  }
                  errorMsg={errorMsg}
                  handleLoginSubmit={handleLoginSubmit}
                  openAccountPopup={openAccountPopup}
                  text={text}
                  isLoggedin={false}
                  $isPopup
                />
              </Suspense>
            )}
          </Suspense>
        )}
      </MenuMobileNav>
      <MenuMobileNav
        data-testid="AccountWrapper"
        className={`${isMenuOpen ? "active" : ""} ${
          isMenuCropped ? "cropped" : ""
        }  `}
      >
        {isMenuOpen && (
          <Suspense fallback={<MazoutLoader />}>
            <MenuMobileHeader>
              <CloseButtonWrapper>
                <Suspense>
                  <LanguageSelector />
                </Suspense>
                <button
                  type="button"
                  onClick={() => toggleMenu(true)}
                  aria-label="Close modal"
                >
                  <Suspense>
                    <Icon icon="xmark-circle-positif" size={30} />
                  </Suspense>
                </button>
              </CloseButtonWrapper>
            </MenuMobileHeader>
            <ItemList>
              {lang?.navigation.menu?.map((item, i) => (
                <li key={`MainMenuMobile_${i}`}>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={`${routeMap?.urls[item?.url] ?? item?.url}`}
                    onClick="toggleMenu()"
                  >
                    {item?.label}
                  </NavLink>
                </li>
              ))}
            </ItemList>
            <SearchWrapper>
              <h3>{text?.offers.searching}</h3>
              <Suspense>
                <MainSearch lang={lang} modeBanner setIsMenuOpen={toggleMenu} />
              </Suspense>
            </SearchWrapper>
          </Suspense>
        )}
      </MenuMobileNav>
    </Wrapper>
  );
};

export default MenuMobile;
const DashboardMenuToggle = styled.button.attrs({
  className: "DashboardMenuToggle",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex: 0 0 auto;
`;

const RightMenuPart = styled.div.attrs({
  className: "RightMenuPart",
})`
  display: flex;

  align-items: center;
  flex: 0 0 auto;

  @media screen and (max-width: 270px) {
    gap: 8px;
  }
  gap: 16px;
`;

const Logo = styled.img.attrs({
  className: "Logo",
})`
  max-width: 300px;
  width: 300px;
  height: 66.883px;
  transition: all 0.1s ease-in-out;
  @media (max-width: 430px) {
    max-width: 200px;
  }
  @media (max-width: 320px) {
    max-width: 150px;
  }
  @media (max-width: 270px) {
    max-width: 100px;
  }
`;

const Burger = styled.div.attrs({
  className: "Burger",
})`
  cursor: pointer;
  flex: 0 0 auto;
`;

const TopBarMenuWrapper = styled.div.attrs({
  className: "TopBarMenuWrapper",
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  min-height: 66px;
  gap: 16px;
`;

const MenuMobileNav = styled.nav.attrs({
  className: "MenuMobileNav",
})`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 100;
  left: calc(100vw + 30px);
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &.active {
    opacity: 1;
    left: 0;
  }
  &.cropped {
    overflow: scroll;
    height: calc(100vh - 100px);
  }
`;

const ItemList = styled.ul.attrs({
  className: "ItemList",
})`
  * {
    color: ${(props) => props.theme.colors.dark};
  }
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.greyDarker};
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${(props) => props.theme.colors.blueLight};
      a {
        color: white;
      }
    }
  }
  a {
    width: 100%;
    display: inline-block;
    padding: 24px;
    color: ${(props) => props.theme.colors.dark};
    &:hover,
    &.active {
      background-color: ${(props) => props.theme.colors.blue};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const MenuMobileHeader = styled.div.attrs({
  className: "MenuMobileHeader",
})`
  z-index: 100;
`;

const Wrapper = styled.div.attrs({
  className: "Wrapper",
})`
  z-index: 100;
  .menu-mobile-logo {
    max-width: 325px;
    margin-bottom: 18px;
  }
  .menu-burger,
  .menu-burger * {
    cursor: pointer !important;
  }
  .menu-burger {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    margin-left: auto;
    scale: 0.8;
  }
  .menu-burger-line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: 4px;
  }
  .menu-burger-line:last-child {
    margin-bottom: 0;
  }

  /* GENERAL STYLES */
  .visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  h1 {
    text-align: center;
  }

  .container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .hamburger {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    position: relative;
  }

  .hamburger .bar {
    padding: 0;
    width: 30px;
    height: 4px;
    background-color: ${(props) => props.theme.colors.blue};
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }

  .bar1 {
    top: 0;
  }

  .bar2,
  .bar3 {
    top: 13.5px;
  }

  .bar3 {
    right: 0;
  }

  .bar4 {
    bottom: 0;
  }
  /* HAMBURGER 3 */

  .hamburger3 .bar1 {
    transform-origin: 5%;
  }

  .hamburger3 .bar4 {
    transform-origin: 5%;
  }

  .checkbox3:checked + label > .hamburger3 > .bar1 {
    transform: rotate(45deg);
    height: 3px;
    width: 42px;
  }

  .checkbox3:checked + label > .hamburger3 > .bar3 {
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
  }

  .checkbox3:checked + label > .hamburger3 > .bar2 {
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
  }

  .checkbox3:checked + label > .hamburger3 > .bar4 {
    transform: rotate(-45deg);
    height: 3px;
    width: 42px;
  }
`;

const SearchWrapper = styled.div.attrs({
  className: "SearchWrapper",
})`
  background-color: ${(props) => props.theme.colors.grey};
  padding: 32px 32px 48px;
`;

const CloseButtonWrapper = styled.div.attrs({
  className: "CloseButtonWrapper",
})`
  position: relative;
  top: 0px;
  height: 50px;
  width: 100%;
  z-index: 100;

  background-color: ${(props) => props.theme.colors.green};
  cursor: pointer;
  button {
    padding: 12px;
    float: right;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      color: #000 !important;
      &,
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
  select {
    height: 50px;
    padding: 0 12px;
  }
`;
