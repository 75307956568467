import styled from 'styled-components';

export const LoginFormWrapper = styled.section.attrs({
  className: 'LoginFormWrapper',
})`
  margin-top: ${({ $isPopup }) => ($isPopup ? '5px' : '0')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  width: auto;
  min-width: 300px;
  box-shadow: ${({ $isPopup }) => (!$isPopup ? '0 2px 10px 0 rgba(0, 0, 0, 0.5)' : '')};

  .success {
    transition: 'all .2s';
    text-align: center;
    color: ${({ theme }) => theme.colors.green};
  }
  .error {
    transition: 'all .2s';
    text-align: center;
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const Header = styled.header.attrs({
  className: 'Header_Popup',
})`
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark};
  padding: 10px 16px;
  background-color: ${({ $isDark, theme }) => ($isDark ? theme.colors.blue : theme.colors.white)};
`;

export const InnerMain = styled.main.attrs({
  className: 'Main',
})`
  padding: 16px;
`;

export const Form = styled.form.attrs({
  className: 'Form',
})`
  display: flex;
  flex-direction: column;
  gap: 12px;
  input {
    width: 100%;
    height: 34px;
    padding: 5px 8px;
    min-height: 16px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.dark};
  }
  a {
    text-align: right;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.dark};
    text-decoration: underline;
  }
`;
export const Title = styled.h4.attrs({
  className: 'Title',
})`
  text-align: center;
  color: ${({ $isDark, theme }) => ($isDark ? theme.colors.white : theme.colors.blueDark)};
`;
export const Footer = styled.footer.attrs({
  className: 'Footer',
})`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.grey};
`;
