import { appDataContext } from "@/context";
import { Helmet } from "react-helmet-async";
import { useContext } from "react";
import { useLangStore } from "@/store/lang-store";

const Head = () => {
  const JSON = useContext(appDataContext);
  const data = JSON?.lang;
  const { langStore } = useLangStore();

  return (
    <Helmet>
      <html lang={langStore ? langStore.substring(0, 2) : "nl"} />
      <meta charset="UTF-8" />
      <meta
        name="description"
        content={data?.site.description || "MAZOUT.COM"}
      />
      <meta
        name="og:description"
        content={data?.site.description || "MAZOUT.COM"}
      />
      <meta property="og:title" content={data?.site.metatitle} />
      <meta property="og:site_name" content="MAZOUT.COM"></meta>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content="https://carbucomstatic-5141.kxcdn.com/mazout_com/images/icon.png"
      />

      <meta name="viewport" content="width=device-width" />
      <link rel="icon" type="image/ico" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        href="https://carbucomstatic-5141.kxcdn.com/mazout_com/images/icon.png"
      />

      <title>{data?.site.metatitle}</title>
    </Helmet>
  );
};

export default Head;
