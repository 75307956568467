const SetMenu = ({ dataContext }) => {
  let menus = [];
  let routeMap = "";
  let lang = "";
  let urlsSecures = "";

  const SetMenuFunc = (dataContext) => {
    if (!dataContext) {
      return;
    }
    routeMap = dataContext?.routeMap;
    lang = dataContext?.lang;
    urlsSecures = routeMap?.urlsSecure;

    if (routeMap && menus.length !== 0) {
      menus = [];
    }

    if (urlsSecures) {
      Object.entries(urlsSecures)?.map(([key, subject]) => {
        menus.push({
          name: subject,
          label: lang?.navigation?.menuSecure?.[key],
          icon: key,
        });
      });
    }
  };

  SetMenuFunc(dataContext);
  return menus;
};

export default SetMenu;
