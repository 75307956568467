import axios from "@/api/axios";

//Article Detailled
export const ART_CATS = {
  "fr-BE": "actualites-mazout-com",
  fr: "actualites-mazout-com",
  "nl-BE": "mazout-com-actualiteit",
  nl: "mazout-com-actualiteit",
};
export const LOGIN_URL = "/auth/login";
export const PSWD_URL = "/auth/lostpassword";
export const RESET_PSWD_URL = "/auth/reset-password";
export const REGISTER_URL = "/auth/register";
export const AUTH_CONFIRM_URL = "auth/confirm";
export const USR_ORDERMESSAGES_URL = "users/$uid/orders/$key/messages";
export const USR_REQUESTS_URL = "users/$uid/requests";
export const AUTH_URL = "/auth";
export const ART_URL = "articles";
export const USR_URL = "users/$uid";
export const USR_ORDERS_URL = "users/$uid/orders";
export const ORDERS_URL = "orders";
export const ORDER_CONFIRM_URL = "orders/confirm";
export const REQUEST_URL = "requests";
export const REQUEST_CONFIRM_URL = "requests/confirm";
export const REQUEST_VIEW_URL = "requests/view";
export const SUMMARYPRICES_URL = "price-summary";
export const BESTPRICES_URL = "best-prices";
export const MAXPRICESURL = "price-history";
export const BESTPRICESURL = "best-price-history";
export const PRODT_URL = "products";
export const OFFERS_URL = "offers";
export const LOCATION_URL = "locations";
export const TEST_URL = "testimonials?";
export const NEWSLETTER_URL = "subscribe";
export const UPDATE_PROMOTION = "subscribe/update-promotion";

export async function apiGetCall(url, lang = "nl-BE", params = {}) {
  if (url !== undefined) {
    let response = await axios.get(url, {
      headers: {
        "Accept-Language": lang,
      },
      params: params,
    });
    return response?.data;
  } else {
    return null;
  }
}

export async function apiPostCall(url, lang = "nl-BE", params = {}) {
  if (url !== undefined) {
    return await axios.post(url, JSON.stringify(params), {
      headers: { "Accept-Language": lang },
    });
  } else {
    return null;
  }
}

export async function apiPutCall(url, lang = "nl-BE", params = {}) {
  if (url !== undefined) {
    return await axios.put(url, JSON.stringify(params), {
      headers: { "Accept-Language": lang },
    });
  } else {
    return null;
  }
}
