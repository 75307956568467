import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "@/layouts/Layout";
import { useLangStore } from "@/store/lang-store";
import { CountryRoutes } from "@/utils/CountryRoutes";
import { PrivateRoutes } from "@/utils/PrivateRoutes";
// import PageTracking from "@/utils/PageTracking";

const Home = lazy(() => import("@/pages/Home"));
const FuelPrices = lazy(() => import("@/pages/staticpages/FuelPrices"));
const FuelCheaper = lazy(() => import("@/pages/staticpages/FuelCheaper"));
//import FuelCheaper from "@/pages/staticpages/FuelCheaper";
const FuelBuyOnline = lazy(() => import("@/pages/staticpages/FuelBuyOnline"));
const GroupPurchasePage = lazy(() =>
  import("@/pages/staticpages/GroupPurchasePage")
);
const WelcomeSupplierPage = lazy(() =>
  import("@/pages/staticpages/WelcomeSupplierPage")
);
const DisclaimerPage = lazy(() => import("@/pages/staticpages/DisclaimerPage"));
const ActualitiesPage = lazy(() => import("@/pages/ActualitiesPage"));
const ActualityPage = lazy(() => import("@/pages/ActualityPage"));
const Newsletter = lazy(() => import("@/pages/NewsletterPage"));
const RegularBuyingPage = lazy(() =>
  import("@/pages/staticpages/RegularBuyingPage")
);
const Offers = lazy(() => import("@/pages/Offers"));
const Contact = lazy(() => import("@/pages/staticpages/Contact"));
const ConfirmOrderPage = lazy(() => import("@/pages/order/ConfirmOrderPage"));
const ConfirmRequestPage = lazy(() =>
  import("@/pages/order/ConfirmRequestPage")
);
const CheckoutIndex = lazy(() => import("@/pages/order/funnel/CheckoutIndex"));
const FunnelForm = lazy(() => import("@/components/funnel/FunnelForm"));
// const ConfirmNewUser = lazy(() =>
//   import("@/components/gateway/ConfirmNewUser")
// );
const ConfirmNewUser = lazy(() => import("@/pages/ConfirmUserPage"));
const RedirectArticleId = lazy(() => import("@/components/RedirectArticleId"));
const UserDashBoard = lazy(() => import("@/pages/pagesSecures/UserDashBoard"));
const UserIndex = lazy(() => import("@/pages/pagesSecures/UserIndex"));
const UserOrderDetail = lazy(() =>
  import("@/pages/pagesSecures/UserOrderDetail")
);
const UserOrders = lazy(() => import("@/pages/pagesSecures/UserOrders"));
const UserProfil = lazy(() => import("@/pages/pagesSecures/UserProfil"));
const UserRequestDetail = lazy(() =>
  import("@/pages/pagesSecures/UserRequestDetail")
);
const UserRequests = lazy(() => import("@/pages/pagesSecures/UserRequests"));
const ResetPasswordPage = lazy(() => import("@/pages/ResetPasswordPage"));
const NotFound = lazy(() => import("@/pages/NotFound"));
const TokenRequestDetail = lazy(() =>
  import("@/pages/request/TokenRequestDetail")
);
const UpdateOptinPartnerPage = lazy(() =>
  import("@/pages/UpdateOptinPartnerPage")
);

const RedirectBasedOnLanguage = () => {
  const { langStore } = useLangStore();
  const targetPath =
    ["fr-BE", "fr-FR", "fr"].indexOf(langStore) > -1 ? "/belgique" : "/belgie";
  return <Navigate to={targetPath} replace={true} />;
};

const App = () => {
  return (
    <>
      {console.log("<Routes>")}
      {/* <PageTracking /> */}
      <Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<RedirectBasedOnLanguage />} />

            {/* ONLY NL Routes */}
            <Route path="belgie">
              <Route
                index
                element={
                  <Suspense>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="mazoutprijs"
                element={
                  <Suspense>
                    <FuelPrices />
                  </Suspense>
                }
              />
              <Route
                path="goedkopere-mazout"
                element={
                  <Suspense>
                    <FuelCheaper />
                  </Suspense>
                }
              />
              <Route
                path="goedkopere-mazout/:province"
                element={
                  <Suspense>
                    <FuelCheaper />
                  </Suspense>
                }
              />
              <Route
                path="goedkopere-mazout/:province/:city"
                element={
                  <Suspense>
                    <FuelCheaper />
                  </Suspense>
                }
              />
              <Route
                path="online-mazout-kopen"
                element={
                  <Suspense>
                    <FuelBuyOnline />
                  </Suspense>
                }
              />
              <Route
                path="groepsaankopen-voor-mazout"
                element={<GroupPurchasePage />}
              />
              <Route path="leveranciers" element={<WelcomeSupplierPage />} />
              <Route
                path="algemene-gebruiksvoorwaarden"
                element={<DisclaimerPage />}
              />
              <Route
                path="mazout-com-actualiteit/alle-artikels"
                element={<ActualitiesPage />}
              />
              <Route
                path="mazout-com-actualiteit/:slug"
                element={<ActualityPage />}
              />
              <Route
                path="frequente-aankopen"
                element={<RegularBuyingPage />}
              />
            </Route>

            {/* ONLY FR Routes */}
            <Route path="belgique">
              <Route
                index
                element={
                  <Suspense>
                    <Home />
                  </Suspense>
                }
              />
              <Route path="prix-mazout-de-chauffage" element={<FuelPrices />} />
              <Route path="mazout-moins-cher" element={<FuelCheaper />} />
              <Route
                path="mazout-moins-cher/:province"
                element={<FuelCheaper />}
              />
              <Route
                path="achat-groupe-de-mazout"
                element={<GroupPurchasePage />}
              />
              <Route path="fournisseurs" element={<WelcomeSupplierPage />} />
              <Route path="conditions-generales" element={<DisclaimerPage />} />
              <Route
                path="achat-regulie-de-mazout"
                element={<RegularBuyingPage />}
              />
              <Route
                path="actualites-mazout-com/tous-les-articles"
                element={<ActualitiesPage />}
              />
              <Route
                path="actualites-mazout-com/:slug"
                element={<ActualityPage />}
              />
              <Route
                path="mazout-moins-cher/:province/:city"
                element={<FuelCheaper />}
              />
              <Route
                path="achat-de-mazout-online"
                element={<FuelBuyOnline />}
              />
            </Route>

            {/* ALL Routes */}
            <Route
              path=":country"
              element={
                <Suspense>
                  <CountryRoutes />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense>
                    <Home />
                  </Suspense>
                }
              />
              <Route path="offers" element={<Offers />} />
              <Route path="contact" element={<Contact />} />
              <Route path="newsletter" element={<Newsletter />} />
              <Route
                path="request/confirm/:key"
                element={<ConfirmRequestPage />}
              />
              <Route
                path="request/success/:key"
                element={<ConfirmRequestPage />}
              />
              <Route path="request/success" element={<ConfirmRequestPage />} />
              <Route path="request/error" element={<ConfirmRequestPage />} />
              <Route
                path="request/view/:token"
                element={
                  <Suspense>
                    <TokenRequestDetail />
                  </Suspense>
                }
              />
              <Route path="order/confirm/:key" element={<ConfirmOrderPage />} />
              <Route path="order/success/:key" element={<ConfirmOrderPage />} />
              <Route path="order/success-h" element={<ConfirmOrderPage />} />
              <Route path="order/success" element={<ConfirmOrderPage />} />
              <Route path="order/error" element={<ConfirmOrderPage />} />
              <Route path="order/pending" element={<ConfirmOrderPage />} />
              <Route path="order" element={<CheckoutIndex />}>
                <Route path="checkout/:key" element={<FunnelForm />} />
              </Route>
              <Route
                path="article/:articleId"
                element={<RedirectArticleId />}
              />
              <Route
                path="account/resetpassword"
                element={<ResetPasswordPage />}
              />
              <Route path="account/confirm" element={<ConfirmNewUser />} />
              <Route
                path="updateoptinpartner"
                element={<UpdateOptinPartnerPage />}
              />

              {/* Secure Routes */}
              <Route
                element={
                  <Suspense>
                    <PrivateRoutes />
                  </Suspense>
                }
              >
                <Route
                  path="account"
                  element={
                    <Suspense>
                      <UserIndex />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense>
                        <UserDashBoard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="dashboard"
                    element={
                      <Suspense>
                        <UserDashBoard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="requests"
                    element={
                      <Suspense>
                        <UserRequests />
                      </Suspense>
                    }
                  />
                  <Route
                    path="requests/:key"
                    element={
                      <Suspense>
                        <UserRequestDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="orders"
                    element={
                      <Suspense>
                        <UserOrders />
                      </Suspense>
                    }
                  />
                  <Route
                    path="orders/:key"
                    element={
                      <Suspense>
                        <UserOrderDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="profil"
                    element={
                      <Suspense>
                        <UserProfil />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route path="404" element={<NotFound />} status={404} />
            <Route path="*" element={<NotFound />} status={404} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
