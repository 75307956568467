import axios from "axios";
import { getUserToken, setUserToken } from "@/api/createTokenProvider";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
  params: {
    api_key: import.meta.env.VITE_API_KEY_CARBU,
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getUserToken()?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      //place your reentry code
      console.log("error 401");
      setUserToken(null);
      // useNavigate("/");

      //return Promise.reject(error);
    }
    console.log(error.response.data);
    return Promise.reject(error);
  }
);

export default instance;
