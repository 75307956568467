import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useCartStore = create(
  persist(
    (set) => ({
      cartStore: null,
      setCartStore: (cartStore) => set({ cartStore: cartStore }),
    }),
    {
      name: "cart-storage",
    }
  )
);
