import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

export const PrivateRoutes = () => {
  const user = JSON.parse(localStorage.getItem("REACT_USER_AUTH"));
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      window.dispatchEvent(
        new CustomEvent("OpenLoginForm", {
          detail: { noRegiter: true, redirect: location.pathname },
        })
      );
    }
  }, [user, location]);

  return <>{user ? <Outlet /> : <Navigate to="/" replace={true} />}</>;
};
