import { useScreenDetector } from "@/utils/useScreenDetector";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

//const Head = lazy(() => import("@/components/Head"));
import Head from "@/components/Head";
//const Menu = lazy(() => import("@/components/Menu"));
import Menu from "@/components/Menu";
//const MenuMobile = lazy(() => import("@/components/MenuMobile"));
import MenuMobile from "@/components/MenuMobile";

const Footer = lazy(() => import("@/components/Footer"));
//import Footer from "@/components/Footer";

const Layout = () => {
  const { isTablet } = useScreenDetector();

  return (
    <>
      <Helmet>
        <title>MAZOUT.COM</title>
        <meta name="robots" content="index" />
      </Helmet>

      <Head />

      {isTablet ? <MenuMobile /> : <Menu />}

      <Main>
        <Suspense>
          <Outlet />
        </Suspense>
      </Main>

      <Suspense>
        <Footer />
      </Suspense>
    </>
  );
};

export default Layout;

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-height: 200px;
`;
