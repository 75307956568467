//import { LNG_DEFAULT } from "@/utils/constants";
// zustand/store.js

import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useLangStore = create(
  persist(
    (set) => ({
      langStore: null,
      setLangStore: (langStore) => {
        let langStoreFiltered =
          ["fr-BE", "fr-FR", "fr"].indexOf(langStore) > -1 ? "fr-BE" : "nl-BE";
        //console.log("🚀 ~ XXXXXXX langStore SET:", langStoreFiltered);
        set({ langStore: langStoreFiltered });
      },
    }),
    {
      name: "lng-storage",
    }
  )
);
