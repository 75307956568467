const theme = {
  gradient: {
    greenBlue: "linear-gradient(45deg, #00a293 0%, #006c95 100%)",
    greenDark: "linear-gradient(45deg, #00a293 0%, #4d4d4d 100%)",
    greenBlueLight: "linear-gradient(45deg, #11d3D4 0%, #1297cc 100%)",
  },
  colors: {
    blue: "#006C95",
    blueDark: "#034b68",
    blueFooter: "#006C95",
    blueLight: "#1297cc",
    dark: "#4d4d4d",
    darker: "#23262d",
    //#00A293
    green: "#008579",
    greenLight: "#0cceba",
    greenDark: "#0a6992",
    grey: "#EEEEEE",
    greyLight: "#FEFEFE",
    greyDark: "#e0e0e0",
    greyDarkBorder: "hsl(0, 0%, 80%)",
    greyDarker: "#666",
    orange: "#FF8C00",
    orangeLight: "#fcf3f2",
    white: "#FFFFFF",
    ghost: "#FFFFFF",
    red: "#cc4b37",
    redTransparant: "#cc4b3722",
    opposite: "#FADBA9",
  },
  fonts: {
    title: "MuseoSansRounded700, sans-serif",
    titleBold: "MuseoSansRounded900, sans-serif",
    body: "Lato, sans-serif",
    bodyBold: "Lato-Black, sans-serif",
    bodyItalic: "Lato-BlackItalic, sans-serif",
    bodyBoldItalic: "Lato-BlackItalic, sans-serif",
    bodyThin: "Lato-Thin, sans-serif",
    bodyThinItalic: "Lato-ThinItalic, sans-serif",
  },
};

export default theme;
