export const LNG_URLS = { "nl-BE": "belgie", "fr-BE": "belgique" };
export const LNG_DEFAULT = "nl-BE";

// export const ICONS_PAYMENT_METHODS = [
//   {
//     id: 1,
//     name: 'cash,',
//     icon: '&#xe922',
//   },
//   {
//     id: 2,
//     name: 'bancontact,',
//     icon: '&#xe921',
//   },
//   {
//     id: 3,
//     name: 'visa,',
//     icon: '&#xe923',
//   },
//   {
//     id: 4,
//     name: 'mastercard,',
//     icon: '&#xe924',
//   },
//   {
//     id: 5,
//     name: 'v2Francais,',
//     icon: '&#xe925',
//   },
//   {
//     id: 6,
//     name: 'cheques,',
//     icon: '&#xe926',
//   },
// ];
